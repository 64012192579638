import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import Carousel from "../Carousel";
import { FetchService } from "../../service/FetchService";
import configData from "../../config.json";
import Navbar from "../Navbar";
import Footer from "../Footer";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import PageLoader from "../pageLoader/PageLoader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Home() {
  const [data, setData] = useState(null);
  const [banner, setBanner] = useState(null);
  const location = useLocation()

  console.log('banner', banner)

  useEffect(() => {
    $("body").removeClass("overflow-hidden");
    $(".footer-info").removeClass("z-inc");
    var prevScrollPos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      // Scroll down
      if (prevScrollPos < currentScrollPos) {
        $(".header").addClass("header-hidden").removeClass("header-visible");
        $("#tile-1")
          .addClass("header-hidden-m")
          .removeClass("header-visible-m");
        $(".footer-info").removeClass("z-inc");
      }
      // Scroll up
      else {
        var scroll = $(window).scrollTop();
        if (scroll <= 100) {
          $(".header")
            .removeClass("header-hidden")
            .removeClass("header-visible");
          $("#tile-1")
            .removeClass("header-hidden-m")
            .removeClass("header-visible-m");
          $(".footer-info").removeClass("z-inc");
        } else {
          $(".header").removeClass("header-hidden").addClass("header-visible");
          $("#tile-1")
            .removeClass("header-hidden-m")
            .addClass("header-visible-m");
          $(".footer-info").removeClass("z-inc");
        }
      }
      prevScrollPos = currentScrollPos;
    };

    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (width <= 768) {
      $("main").click(function () {
        $(".overlay-123").removeClass("d-block");
      });

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if (
          $(window).scrollTop() + $(window).height() >
          $(document).height() - 280
        ) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
        }
      });

      $(".footer-info .animate-image span").click(function () {
        $(".footer-info").addClass("z-inc");
        $("#btn-back-to-top").addClass("d-none");
        $(".overlay-123").addClass("d-block");
        disableScroll();
      });
    }

    var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (width >= 768) {
      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if (
          $(window).scrollTop() + $(window).height() >
          $(document).height() - 660
        ) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
          console.log(width);
        }
      });

      $(".animate-image").hover(
        function () {
          $(".footer-info").addClass("z-inc");
          $("#btn-back-to-top").addClass("d-none");
          // $('body').addClass('overflow-hidden');
          $(".overlay-123").addClass("d-block");
          disableScroll();
        },
        function () {
          $("#btn-back-to-top").removeClass("d-none");
          //$('body').removeClass('overflow-hidden');
          $(".overlay-123").removeClass("d-block");
          enableScroll();
        }
      );
    }

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 200) {
        $(".header").removeClass("bg-none");
        $("#btn-back-to-top").addClass("d-block");
      } else {
        $(".header").addClass("bg-none");
        $("#btn-back-to-top").removeClass("d-block");
      }
    });

    $(".navbar-toggler").click(function () {
      if ($(".navbar-collapse").find(".show")) {
        $("body").toggleClass("scrollremove");
        $(".navbar-brand").toggleClass("logo-invert");
      } else {
        $("body").removeClass("scrollremove");
        $(".navbar-brand").removeClass("logo-invert");
      }
    });

    // $(document).ready(function () {
    //   $('#PMD-Slider').on('slide.bs.carousel', function (e) {
    //     console.log("sddffgd");
    //     var nextSlide = $(e.relatedTarget);
    //     var video = nextSlide.find('video');
    //     if (video.length > 0) {
    //       console.log('active video', video[0])
    //       video[0].currentTime = 0;
    //       video[0].play();
    //     }
    //   });
    // });

    $(document).ready(function () {
      // set time out 5 sec
      setTimeout(function () {
        $(".carousel-indicators .item__2").trigger("click");
      }, 3500);
    });

    // $(document).ready(function () {
    //   $('#PMD-Slider').on('slide.bs.carousel', function (e) {
    //     var nextSlide = $(e.relatedTarget);
    //     var video = nextSlide.find('video');

    //     // $('#PMD-Slider .carousel-item').each(function () {
    //     //   // this.stop();
    //     //   this.currentTime = 0;
    //     // });

    //     if (video.length > 0) {
    //       // video[0].play();

    //       // video.on('ended', function () {
    //       //   $('#PMD-Slider').carousel(0);
    //       // });
    //     }
    //   });

    //   // setTimeout(function () {
    //   //   $('#PMD-Slider').carousel(1);
    //   // }, 3500);
    // });

    $(".overlay-123").click(function () {
      $(".overlay-123").removeClass("d-block");
      setTimeout(function () {
        $(".footer-info").removeClass("z-inc");
      }, 500);
      enableScroll();
    });
    function show_12() {
      $(".hidden-up").addClass("show");
      $("body").addClass("overflow-hidden");
    }

    function hide_12() {
      $(".hidden-up").removeClass("show");
      $("body").removeClass("overflow-hidden");
    }

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    function preventDefault(e) {
      e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
          },
        })
      );
    } catch (e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    // call this to Disable
    function disableScroll() {
      window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
      window.addEventListener("keydown", preventDefaultForScrollKeys, false);
    }

    // call this to Enable
    function enableScroll() {
      window.removeEventListener("DOMMouseScroll", preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener("touchmove", preventDefault, wheelOpt);
      window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
    }
  });

  useEffect(() => {
    const fetchService = new FetchService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "product/projectslist?items=200",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y",
      },
    };
    fetchService.getService(config).then((data) => setData(data["result"]));
  }, []);

  useEffect(() => {
    const fetchService = new FetchService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + "banner/list?items=100",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y",
      },
    };
    fetchService.getService(config).then((data) => setBanner(data["result"]));
  }, []);

  const showAllCategory = (item) => {
    const category = item.scope_category;
    let splitArray = category.split(",");

    // Add spaces after each comma
    let formattedString = splitArray.join(", ");

    // Replace the last comma with "and" and remove the space after it
    formattedString = formattedString.replace(/,([^,]*)$/, " and$1");
    return formattedString;
  };

  const sliderRef = useRef(null);
  const videoRefs = useRef([]);


  useEffect(() => {
    const slider = sliderRef.current;

    // Access the Bootstrap Carousel via window.bootstrap
    if (slider && window.bootstrap) {
      const carousel = new window.bootstrap.Carousel(slider, {
        interval: false, // Disable automatic sliding
      });

      const handleSlide = (e) => {
        const previousSlide = e.relatedTarget.previousElementSibling;
        if (previousSlide) {
          const prevVideo = previousSlide.querySelector("video");
          if (prevVideo) {
            console.log("Stopping previous video:", prevVideo);
            prevVideo.pause();
            prevVideo.currentTime = 0;
          }
        }
      };

      const handleSlid = (e) => {
        const activeSlide = slider.querySelector(".carousel-item.active");
        if (activeSlide) {
          const activeVideo = activeSlide.querySelector("video");
          if (activeVideo) {
            console.log("Starting next video:", activeVideo);
            activeVideo.currentTime = 0;
            activeVideo.play();
          }
        }
      };

      slider.addEventListener("slide.bs.carousel", handleSlide);
      slider.addEventListener("slid.bs.carousel", handleSlid);

      // Cleanup
      return () => {
        slider.removeEventListener("slide.bs.carousel", handleSlide);
        slider.removeEventListener("slid.bs.carousel", handleSlid);
        carousel.dispose(); // Cleanup carousel instance
      };
    }
  }, [banner]);

  const isVideo = (url) => {
    const videoExtensions = [
      "mp4",
      "webm",
      "ogg",
      "avi",
      "mov",
      "wmv",
      "flv",
      "mkv",
    ];
    const extension = url.split(".").pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  return (
    <>
      <div className="home" style={{ overflow: "hidden" }}>
        <div className="header bg-none">
          <Navbar isHomePage={true} />
        </div>
        <main className="main-sec">
          {/* home section slider*/}
          <div className="ultra-padding position-fixed sec-1 w-100">
            {
              banner?.length ? 
                <div
                  id="PMD-Slider"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  data-bs-pause="false"
                  ref={sliderRef}
                >
                  <div className="carousel-inner">
                    {
                      banner?.length ? 
                            banner?.map((item, index)=> (
                              <div key={item?._id} className={`carousel-item item-${index + 1} ${index === 0 ? 'active' : ''}`} data-bs-interval={3100}>
                                <video autoPlay muted loop ref={(el) => (videoRefs.current[index] = el)}  data-src={configData.API_BASE_URL +'/'+ item?.banner_image}>
                                  <source src={configData.API_BASE_URL +'/'+ item?.banner_image} type="video/mp4" />
                                </video>
                                <Link to={`/project/${item?.project_link}`}>
                                  <div className="carousel-caption text-start">
                                    <div className="head">
                                      <h3 className="display-6 mb-2 text-white fw-bold">
                                        {item?.content}
                                      </h3>
                                    </div>
                                    <div className="para">
                                      <p className="fs-5 mb-0 text-white">
                                        {item?.content1}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))
                      : 'No banner available'
                    }
                    
                    <div className="carousel-indicators">
                      {
                        banner?.length ? 
                            banner?.map((item, index)=> (
                              <button
                              key={index}
                              type="button"
                              data-bs-target="#PMD-Slider"
                              data-bs-slide-to={index}
                              className={`${index === 0 ? 'active' : ''}`}
                              aria-current="true"
                              aria-label={`Slide ${index + 1}`}
                            />
                            ))
                        : ''
                      }
                    </div>
                  </div>
                  <div className="Logo-area px-0 d-none d-lg-block">
                    <div className="container ps-0">
                      <Link
                        to="/"
                        onClick={(event) => {
                          event.preventDefault();
                          window.location.href = "/";
                        }}
                        className="logo-img"
                      >
                        <img
                          src="./img/pmd-logo-black.svg"
                          alt=""
                          width="auto"
                          height="48px"
                          className=""
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              
              : ''
            }
          </div>
          <div className="ultra-padding">
            <div className="bg-white position-relative sec-2">
              {/* start 2nd section */}
              <section className="section-2 pv-top-60 mb-md-4 px-lg-0">
                <div className="container px-1">
                  <div className="row">
                    {data &&
                      data.slice(0, 2).map((item, index) => (
                        <div key={index} className="col-md-6 pr-20">
                          <Link
                            className="link-fs-22"
                            to={{
                              pathname: `/project/${item.productName
                                .toLowerCase()
                                .replace(/[^a-z0-9-]/g, "-")
                                .replace(/-+/g, "-")
                                .replace(/^-|-$/g, "")}`,
                              state: { data: item._id },
                            }}
                          >
                            <div className="card border-0 ">
                              <div className="img-box">
                                {/* <img src={configData.API_BASE_URL + '/' + item.images} alt="" /> */}
                                {isVideo(item.images) ? (
                                  <video
                                    loop
                                    autoPlay
                                    muted
                                    className="img-fluid"
                                  >
                                    <source
                                      src={
                                        configData.API_BASE_URL +
                                        "/" +
                                        item.images
                                      }
                                      type={`video/${item.images
                                        .split(".")
                                        .pop()}`}
                                    />
                                  </video>
                                ) : (
                                  <img
                                    src={
                                      configData.API_BASE_URL +
                                      "/" +
                                      item.images
                                    }
                                    alt="Media content"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="card-body px-0 pt-10">
                                <h3 className="mb-0 color-black fs-22">
                                  {item.productName}
                                </h3>
                                <p className="fs-6 lh-scope mt-1 ibmsans-regular mb-0 color-black">
                                  {showAllCategory(item)}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
              {/* end 2nd section */}
              {/* start 3rd section  */}
              <section className="section-3 mb-5 px-lg-0">
                <div className="container px-1">
                  <div className="row">
                    {data &&
                      data.slice(2, 3).map((item, index) => (
                        <div key={index} className="col-md-12">
                          <Link
                            className="link-fs-22"
                            to={{
                              pathname: `/project/${item.productName
                                .toLowerCase()
                                .replace(/[^a-z0-9-]/g, "-")
                                .replace(/-+/g, "-")
                                .replace(/^-|-$/g, "")}`,
                              state: { data: item._id },
                            }}
                          >
                            <div className="card border-0">
                              <div className="img-box">
                                {/* <img src={configData.API_BASE_URL + '/' + item.images} alt="" /> */}
                                {isVideo(item.images) ? (
                                  <video
                                    loop
                                    autoPlay
                                    muted
                                    className="img-fluid"
                                  >
                                    <source
                                      src={
                                        configData.API_BASE_URL +
                                        "/" +
                                        item.images
                                      }
                                      type={`video/${item.images
                                        .split(".")
                                        .pop()}`}
                                    />
                                  </video>
                                ) : (
                                  <img
                                    src={
                                      configData.API_BASE_URL +
                                      "/" +
                                      item.images
                                    }
                                    alt="Media content"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="card-body px-0 pt-10">
                                <h3 className="mb-0 color-black fs-22">
                                  {item.productName}
                                </h3>
                                <p className="fs-6 lh-scope mt-1 ibmsans-regular mb-0 color-black">
                                  {showAllCategory(item)}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
              {/* end 3rd section  */}
              {/* section 4 start */}
              <section className="section-4 pv-bottom-60 px-lg-0">
                <div className="container px-1">
                  <div className="row">
                    {data &&
                      data.slice(3, 19).map((item, index) => (
                        <div key={index} className="col-md-3 mb-4">
                          <Link
                            className="link-fs-22"
                            to={{
                              pathname: `/project/${item.productName
                                .toLowerCase()
                                .replace(/[^a-z0-9-]/g, "-")
                                .replace(/-+/g, "-")
                                .replace(/^-|-$/g, "")}`,
                              state: { data: item._id },
                            }}
                          >
                            <div className="card border-0">
                              <div className="img-box">
                                {/* <img src={configData.API_BASE_URL + '/' + item.images} className="img-fluid" /> */}
                                {isVideo(item.images) ? (
                                  <video
                                    loop
                                    autoPlay
                                    muted
                                    className="img-fluid"
                                  >
                                    <source
                                      src={
                                        configData.API_BASE_URL +
                                        "/" +
                                        item.images
                                      }
                                      type={`video/${item.images
                                        .split(".")
                                        .pop()}`}
                                    />
                                  </video>
                                ) : (
                                  <img
                                    src={
                                      configData.API_BASE_URL +
                                      "/" +
                                      item.images
                                    }
                                    alt="Media content"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="card-body px-0 pt-10">
                                <h3 className="mb-0 color-black fs-22">
                                  {item.productName}
                                </h3>
                                <p className="fs-6 lh-scope mt-1 ibmsans-regular mb-0 color-black">
                                  {showAllCategory(item)}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
                <p className="mb-0 text-center text-uppercase pt-3">
                  <Link
                    to="/project"
                    className="fs-5 color-gray hov-color-black"
                  >
                    View all Projects
                  </Link>
                </p>
              </section>
            </div>
          </div>
          {/* section 4 end */}
          <section
            className="testimonial pv-60"
            style={{ background: "#292929" }}
          >
            <div className="container ">
              <div
                id="testimonial_slider"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner quote-icon">
                  <div className="carousel-item active" data-bs-interval={3100}>
                    <div className="row">
                      <div className="col-md-4 d-flex mt-5 mt-sm-5 mt-md-0 order-md-first order-sm-last">
                        <div className="image-area">
                          <img
                            src="img/piyush-image.png"
                            width="61px"
                            height="auto"
                          />
                        </div>
                        <div className="testimonial-from ms-3">
                          <h6 className="text-white ibm-sans fw-medium">
                            Peyush Bansal
                          </h6>
                          <p className="designation mb-3 fst-italic">
                            Co-Founder, Chief Executive &amp; People Officer
                          </p>
                          <p className="company mb-0 text-white ">
                            Lenskart.com
                          </p>
                        </div>
                      </div>
                      <div className="col-md-7 offset-md-1 ms-0 mt-sm-3 mt-md-0">
                        <p
                          className="text-white fs-5 lh-28 position-relative mb-0 ibm-sans"
                          style={{ zIndex: 0 }}
                        >
                          I am incredibly impressed with the outstanding work
                          that PMD India has done for Lenskart. Their innovative
                          approach to designing our brand language has been
                          instrumental in shaping Lenskart's identity and
                          customer experience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item" data-bs-interval={3100}>
                    <div className="row">
                      <div className="col-md-4 d-flex mt-5 mt-sm-5 mt-md-0 order-md-first order-sm-last">
                        <div className="image-area">
                          <img
                            src="img/shikha.jpg"
                            width="61px"
                            height="auto"
                          />
                        </div>
                        <div className="testimonial-from ms-3">
                          <h6 className="text-white ibm-sans fw-medium">
                            Shikha Pahwa Verma
                          </h6>
                          <p className="designation mb-3 fst-italic">Founder</p>
                          <p className="company mb-0 text-white ">HouseThis</p>
                        </div>
                      </div>
                      <div className="col-md-7 offset-md-1 ms-0 mt-sm-3 mt-md-0">
                        <p
                          className="text-white fs-5 lh-28 position-relative mb-0 ibm-sans"
                          style={{ zIndex: 0 }}
                        >
                          Our association with PMD has been built over many
                          years, owing to not just their exceptional design
                          acumen but also their ethos of cultivating
                          partnerships for the long term. Prasun and his team
                          have been true allies in our journey right from
                          inception- from logo design to communication campaigns
                          and to brand extensions. I can say with utmost
                          conviction, that they would add tremendous value to
                          any and all brand building efforts.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex testimonial-arrow justify-content-between mt-4">
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#testimonial_slider"
                    data-bs-slide="prev"
                  >
                    <i className="fas fa-arrow-left" />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#testimonial_slider"
                    data-bs-slide="next"
                  >
                    <i className="fas fa-arrow-right" />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default Home;
