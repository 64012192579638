import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import { showLoader } from "../utils/ShowLoader";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };

  useEffect(() => {
  })

  return (
    <>
      {/* Footer */}
      {/* <div className="home"> */}
      <footer id="footer" className="">
        <div className="footer-bg">
          <div className="container text-md-center text-sm-start">
            <div className="row">
              <div className="main-text pv-60">
                <p className="fs-4 mb-3">Come for the design, stay for the chai</p>
                <div className="heading">
                  <h3 className="display-2 color-black">
                    We'd love to <i>welcome</i> you to our studio!
                  </h3>
                  <ul className="d-md-flex d-sm-block list-unstyled justify-content-md-center justify-content-sm-start mt-103 mb-sm-2">
                    <li>
                      <Link className="nav-link" to="/project" 
                      // onClick={(event) => { event.preventDefault(); window.location.href = '/project' }}
                      onClick={showLoader}
                      >Projects</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/logofolio" 
                      // onClick={(event) => { event.preventDefault(); window.location.href = '/logofolio' }}
                      onClick={showLoader}
                      >Logofolio</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/clients" 
                      // onClick={(event) => { event.preventDefault(); window.location.href = '/clients' }}
                      onClick={showLoader}
                      >Clients</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/about" 
                      // onClick={(event) => { event.preventDefault(); window.location.href = '/about' }}
                      onClick={showLoader}
                      >About</Link>
                    </li>
                    <li>
                      <Link className="nav-link" to="/contact" 
                      // onClick={(event) => { event.preventDefault(); window.location.href = '/contact' }}
                      onClick={showLoader}
                      >Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="row site_information-1 mt-103">
                  <div className="container text-center">
                    <div className="row align-items-end">
                      <div className="col-md-4 col-sm-6 foot-social">
                        <div className="social-links mb-md-1">
                          <ul className="list-unstyled d-flex justify-content-md-center justify-content-sm-start">
                            <li>
                              <a href="https://www.linkedin.com/company/prasun-mazumdar-design/?trk=mini-profile" target="_blank">
                                <img
                                  src="./img/social-icons/linkedin.svg"
                                  className="img-fluid"
                                  width={34}
                                />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/pmdindia/" target="_blank">
                                <img
                                  src="./img/social-icons/instagram.svg"
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/pmdindia" target="_blank">
                                <img
                                  src="./img/social-icons/facebook.svg"
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.youtube.com/@pmdindia" target="_blank">
                                <img
                                  src="./img/social-icons/youtube.svg"
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p className="mb-0 text-sm-start text-md-center text-start">
                          <a
                            target="_blank"
                            href="https://maps.google.com/maps/dir//PMDIndia+G+3%2F10+G+Block,+DLF+Phase+1,+Sector+26+Gurugram,+Haryana+122002/@28.4732147,77.1055832,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x390ce2099137797f:0xb38315516012139b"
                            className="direction color-black fs-5 ibm-sans d-inline-flex align-items-center text-decoration-none"
                          >
                            <span>Get Directions</span>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.88526 11.2917L0.75 10.1564L8.53462 2.37179H1.5609V0.75H11.2917V10.4808H9.66987V3.50705L1.88526 11.2917Z" fill="black" />
                            </svg>
                          </a>
                        </p>
                      </div>
                      <div className="col-md-4 col-sm-6 text-sm-start text-md-center text-start foot-logo pb-md-1">
                        <Link to="/" 
                        // onClick={(event) => { event.preventDefault(); window.location.href='/' }}
                        onClick={showLoader}
                        >
                          <img
                            src="./img/pmd-logo-black.svg"
                            width="auto"
                            height="68px"
                          />
                        </Link>
                      </div>
                      <div className="col-md-4 col-sm-12 text-start">
                        <div className="divider w-100 m-b-up mobile-vis" />
                        <p className="mb-md-3 mb-1">
                          <a
                            href="mailto:prasun@pmdindia.in"
                            className="email-tag color-black fs-5"
                          >
                            prasun@pmdindia.in
                          </a>
                        </p>
                        <p className="mb-0">
                          <a
                            href="tel:+919871350426"
                            className="color-black fs-5 text-decoration-none lh-1"
                          >
                            +91 9871350426
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div class="footer-info site_information">
        <div class="container">
          <div class="animate-image desktop-vis">

            <div class="initiative-img"><span>
              <font>OUR ART <br /> INITIATIVE</font>
            </span>
            <a href="https://thesparrow.in/" target="_blank" style={{ textDecoration: 'none' }}>
            <img src="img/desktop_sparrow.svg" class="img-fluid" />
            {/* <img src="img/sparrow-green.jpg" class="img-fluid" /> */}
            </a>
            </div>

          </div>

          {   /*   <a href="https://thesparrow.in/" target="_blank" rel="noopener noreferrer">
            <div className="initiative-img">
              <span>
                <font>OUR ART hfjhdjg<br />INITIATIVE</font>
              </span>
              <img src="img/sparrow-green.jpg" className="img-fluid" alt="Sparrow Green" />
            </div>
          </a> */}



          <div class="animate-image mobile-vis"><span class="art-span">
            <font style={{ color: 'black' }}>OUR ART <br /> INITIATIVE</font>
          </span>
            <a href="https://thesparrow.in/" target="_blank" style={{ textDecoration: 'none' }}>
              <div class="initiative-img">
                <img src="img/mobile_sparrow.svg" class="img-fluid" />
                {/* <img src="img/sparrow-banner-mobile.svg" class="img-fluid" /> */}
                </div>
            </a>

          </div>

        </div>
        <div class="overlay-123"></div>
      </div >
      <button id="btn-back-to-top" onClick={handleClick} className="bt-top-head"><i className="far fa-arrow-up text-white"></i></button>
      {/* Footer End */}
      {/* <script
          dangerouslySetInnerHTML={{ __html:
            `var prevScrollPos = window.pageYOffset;
            window.onscroll = function () {
              var currentScrollPos = window.pageYOffset;
              // Scroll down 
              if (prevScrollPos < currentScrollPos) {
                $(".header").addClass("header-hidden").removeClass("header-visible");
              }
              // Scroll up 
              else {
                var scroll = jQuery(window).scrollTop();
                if (scroll <= 100) {
                  $(".header").removeClass("header-hidden").removeClass("header-visible");
                }else{
                  $(".header").removeClass("header-hidden").addClass("header-visible");
                }
              }
              prevScrollPos = currentScrollPos;
            };`
          }}
        /> */}
    </>
  );
}

export default Footer;
